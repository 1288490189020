.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../assets/wavy-background.png");
    background-size: cover;
    background-position: center;
    font-family: 'Inter', sans-serif;
}
  
.reset-password-card {
    background: rgba(255, 255, 255, 0.95);
    padding: 2rem;
    border-radius: 16px;
    width: 400px;
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-items: center;
}
  
.reset-password-card header {
    margin-bottom: 1rem;
}

.reset-password-card header img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.reset-password-card h1 {
    color: "#5448F3";
    margin-bottom: 0.5rem;
}

.reset-password-card p {
    color: black;
    margin-bottom: 1.5rem;
}

.input-group {
    margin-bottom: 1rem;
    position: relative;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
}

.input-group input {
    width: 100%;
    height: 3rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
}

.input-group input:focus {
    border: 1px solid #878787 !important;
    outline: none !important;
}

.password-instructions {
    font-size: 0.8rem;
    color: #878787;
    margin-bottom: 3rem;
}

#confirm-password::placeholder {
    font-family: 'Inter', sans-serif;
    color: #878787;
    font-size: 0.85rem;
}

.submit-btn {
    width: 100%;
    background-color: #5448F3;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 2.5rem;
    enable-background: "red";
}

.submit-btn:hover {
    background-color: "#5448F3";
}

.submit-btn:disabled {
    background-color: #b9b4f6;
    cursor: not-allowed;
}

.toggle-password-visibility {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;
  }
  
.toggle-password-visibility svg {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 16px;
    top: 0px;
    transform: translateY(-60%);
}
.toggle-password-visibility:hover {
    color: #007bff;
}

.toggle-password-visibility:focus {
    outline: none;
/* Optional: add focus style that matches your design, e.g., a box-shadow or border */
}

.toggle-password-visibility::-ms-reveal {
    display: none;
}

.text-header {
    color: #0e0e0e;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Inter', sans-serif;
}

.text-body {
    color: #0e0e0e;
    font-size: 1.0rem;
    margin-top: -0.75rem;
    text-align: center;
    font-family: 'Inter', sans-serif;
}